

export const types = {
    contactos: {
       EMAIL:     'mailto: andres230687@hotmail.com',
       WHATSAPP: 'whatsapp://send?text=Hola&phone=+57xxx.xxx.xx.xx'

    },
    storage: {
        userStorage: '@QJ45w9vZ_storage_User',
        tokenStorage: '@UW75a2bA_storage_Token',
    }
} 